<template>
	<v-btn-toggle dense color="primary" mandatory v-model="lazyValuei.btn">
		<v-text-field dense hide-details style="max-width:96px;" reverse :prefix="prefix" :suffix="suffix" v-model="lazyValuei.txt"/>
		<v-btn v-for="item in items" :key="item" :value="item" class="btns">{{item}}</v-btn>
    </v-btn-toggle>
</template>

<script>
	export default {
		name: 'qtxtbtns',
		props: {
            prefix: String,
			suffix: String,
			items: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				lazyValuei: {
					txt: undefined,
					btn: undefined,
				},
			}
		},
		computed: {
			lazyValue: {
				set(v) {
					if (v) {
						this.lazyValuei = v;
					} else {
						this.lazyValuei = {
							txt: undefined,
							btn: undefined,
						}
					}
				},
				get() {
					return this.lazyValuei;
				}
			}
		},
	}
</script>

<style>
    .btns {
        min-width:30px!important;
        height:30px!important;
        margin-top:2px!important;
    }
</style>
