<template>
	<div>
		<v-checkbox v-if="false"/>	<!--骗过编译器-->
		<v-textarea v-if="false"/>	<!--骗过编译器-->
		<div id="printq" class="scalep">
			<div class="onlyp">
				<div style="font-size:16px;margin-left:24px;">基本信息</div>
				<div style="display:flex;flex-wrap:wrap;justify-content:space-between;column-gap:12px;row-gap:2px;margin-left:40px;">
					<v-text-field readonly hide-details style="flex:0 1 25%" label="姓名" :value="mypatient.pii.username"/>
					<v-text-field readonly hide-details style="flex:0 1 15%" label="性别" :value="mypatient.pii.gender"/>
					<v-text-field readonly hide-details style="flex:0 1 15%" label="年龄" :value="age"/>
					<v-text-field readonly hide-details style="flex:0 1 35%" label="证件号" :value="mypatient.pii.idcard"/>
					<v-text-field readonly hide-details style="flex:0 1 25%" label="检查项目" :value="mypatient.opts.GIE"/>
					<v-text-field readonly hide-details style="flex:0 1 34%" label="评估时间" :value="evalDate"/>
					<v-text-field readonly hide-details style="flex:0 1 35%" label="病案号" :value="mypatient.pid"/>
				</div>
				<div style="font-size:16px;margin-left:24px;margin-top:24px;">评估内容</div>
			</div>
			<v-form :readonly="readonly">
			<table style="border-spacing:0 8px;width:100%;">
				<tr v-for="q in qs" :key="q.caption" :class="{'displaynone':!q.visible}">
					<td align="right" valign="top" style="width:150px;padding-top:5px;">{{q.caption}}：</td>
					<td style="display:flex;flex-wrap:wrap;column-gap:12px;row-gap:0;">
						<!--
						<component v-for="c in q.items" :key="c.label" v-bind:is="c.type" hide-details
							dense :label="c.label" :ref="q.caption+'：'+c.label" :items="c.items" v-bind="c.attrs"/>
						-->
						<template v-for="c in q.items">
							<component v-if="c.type==='v-text-field' || c.type==='v-textarea'" v-bind:is="c.type" hide-details
								dense :placeholder="c.label" :ref="q.caption+'：'+c.label" :items="c.items" v-bind="c.attrs"/>
							<component v-else v-bind:is="c.type" hide-details
								dense :label="c.label2 || c.label" :ref="q.caption+'：'+c.label" :items="c.items" v-bind="c.attrs" :class="{'displaynone':!c.visible}"/>
						</template>
					</td>
				</tr>
			</table>
			</v-form>
		</div>
		<div style="display:none">
			<div id="printmr" style="line-height:32px;width:100vw;position:relative;border:1px solid #fff;">
				<div style="position:absolute;top:30px;left:20px;right:20px;">
					<img src='/huasanlogo1.png' height="70"/>
					<div style="position:absolute;top:0;right:0;text-align:right;line-height:16px;font-size:8px;">
						<p>北京华三诊所 Beijing Huasan Clinic</p>
						<p>北京市朝阳区朝阳门外大街18号丰联广场2层，邮编：100020</p>
						<p>2nd Floor, Fenglian Plaza, No.18 Chaoyangmenwai Street,</p>
						<p>Chaoyang District, Beijing, 100020 P.R.CHINA</p>
						<p>TEL/电话：400-160-3939</p>
					</div>
				</div>
				<div style="font-size:26px;font-weight:bold;width:100%;text-align:center;margin-top:112px;margin-bottom:20px;">病历记录</div>
				<div style="width:92%;margin:0 auto;">
					<table class="prttable">
						<tr>
							<td width="30%">姓名：{{mypatient.pii.username}}</td>
							<td width="20%">性别：{{mypatient.pii.gender}}</td>
							<td width="20%">年龄：{{age}}岁</td>
							<td>病案号：{{mypatient.pid}}</td>
						</tr>
						<tr>
							<td width="20%">科室：内科</td>
							<td width="20%"/>
							<td width="20%"/>
							<td width="40%">就诊日期：{{evalDate}}</td>
						</tr>
					</table>
				</div>
				<div style="height:65vh;border-top:1px solid #999;border-bottom:1px solid #999;">
					<table class="prttable" style="padding-top:16px;padding-right:50px;">
						<tr v-for="item in mrs" valign="top">
							<td width="18%" align="right">{{item.caption}}： </td>
							<td width="2%"/>
							<td width="80%">{{item.text}}</td>
						</tr>
					</table>
				</div>
				<div style="width:96%;margin:0 auto;padding-top:20px;padding-left:300px;">
					<p style="font-size:18px!important;">医师签名（签章）：</p>
				</div>
			</div>
		</div>
		<!--Note that the type="button" attribute is important, since its missing value default is the Submit Button state-->
		<button id="eqprint" v-print="'#printq'" type="button" style="display:none"/>
		<button id="eqprintmr" v-print="'#printmr'" type="button" style="display:none"/>
	</div>
</template>

<script>
import {formatDate, calculateAge} from '../utils'
import qradio from '@/components/q/qradio.vue'
import qtxtbtns from '@/components/q/qtxtbtns.vue'
import qnumtxt from '@/components/q/qnumtxt.vue'
import print from 'vue-print-nb'

export default {
	directives: {
		print   
	},
    components: {
        qradio,
        qtxtbtns,
		qnumtxt,
    },
	props: {
		patient: Object,
		readonly: {
			type: Boolean,
			default: false,
		}
	},
	watch: {
		patient(v) {
			this.mypatient = v ? v : this.$options.data().mypatient;
			this.qs.forEach(x => {
				x.visible = !x.condition || x.condition(this.mypatient);
				x.items.forEach(y => {
					y.visible = !y.condition || y.condition(this.mypatient);
				});
			});
			this.setValues();
		}
	},
	mounted() {
		this.mypatient = this.patient;
		this.qs.forEach(x => {
			x.visible = !x.condition || x.condition(this.mypatient);
			x.items.forEach(y => {
				y.visible = !y.condition || y.condition(this.mypatient);
			});
		});
		this.setValues();
	},
	computed: {
		age: {
			get() {
				return this.mypatient ? calculateAge(this.mypatient.pii.dob) : '-'
			},
		},
		evalDate() {
//			const t = this.mypatient?.progress?.evalQ || new Date();
			const t = this.mypatient?.apntEvalDate || new Date();
			return formatDate(t);
		},
	},
	data() {
		return {
			mrs: [],
			mypatient: {pii:{},opts:{},progress:{}},
            qs: [{
					caption: '检查目的',
					items: [
						{type:'v-checkbox', label:'健康筛查', label2:'胃肠肿瘤筛查'},
						{type:'v-checkbox', label:'复查', label2:'内镜检查后复查'},
						{type:'v-checkbox', label:'出现不适症状', label2:'消化系统不适症状'},
						{type:'v-textarea', label:'症状描述', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
                }, {
					caption: '胃肠镜检查史',
					items: [
						{type:'v-checkbox', label:'无'},
						{type:'v-checkbox', label:'胃镜'},
						{type:'v-checkbox', label:'肠镜'},
						{type:'v-checkbox', label:'无痛'},
						{type:'v-checkbox', label:'普通'},
						{type:'v-textarea', label:'诊断', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
				}, {
					caption: 'HP检查史',
					condition: (patient) => ['胃镜','胃肠镜'].includes(patient.opts.GIE),
					items: [
						{type:'qradio', label:'检查史', items:['无','阴性','阴性（治疗后）','阳性','其他']},
						{type:'v-textarea', label:'说明', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
				}, {
					caption: '排便习惯',
					condition: (patient) => ['肠镜','胃肠镜'].includes(patient.opts.GIE),
					items: [
						{type:'v-checkbox', label:'正常'},
						{type:'qnumtxt', label:"规律", items:['天','次'], attrs:{style:{width:'120px'}}},
						{type:'v-checkbox', label:'不规律'},
						{type:'v-checkbox', label:'便秘'},
						{type:'v-textarea', label:'其他', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
				}, {
					caption: '吸烟史',
					items: [
						{type:'qradio', label:'吸烟史', items:['无','已戒烟','有']},
						{type:'qnumtxt', label:"频率", items:['支/天'], attrs:{style:{width:'120px'}}},
						{type:'v-text-field', label:'其他'},
					]
				}, {
					caption: '饮酒史',
					items: [
						{type:'qradio', label:'饮酒史', items:['无','偶尔','已戒酒','有']},
						{type:'qtxtbtns', label:'周月年', items:['周','月','年'], attrs:{prefix:'次/'}},
						{type:'qtxtbtns', label:'两瓶', items:['两','瓶'], attrs:{suffix:'每次'}},
					]
				}, {
					caption: '运动习惯',
					items: [
						{type:'qradio', label:'运动习惯', items:['爬三层楼无不适','爬三层楼不适']},
					]
				}, {
					caption: '孕期',
					items: [
						{type:'qradio', label:'孕期', items:['否','是']},
					]
				}, {
					caption: '哺乳期',
					items: [
						{type:'qradio', label:'哺乳期', items:['否','是']},
					]
				}, {
					caption: '备孕计划',
					items: [
						{type:'qradio', label:'备孕计划', items:['否','是']},
					]
				}, {
					caption: '手术史',
					items: [
						{type:'qradio', label:'手术史', items:['无','有']},
						{type:'v-textarea', label:'说明', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
				}, {
					caption: '过敏史',
					items: [
						{type:'qradio', label:'过敏史', items:['无','有']},
						{type:'v-text-field', label:'说明'},
					]
				}, {
					caption: '不适症状',
					items: [
						{type:'v-checkbox', label:'无'},
						{type:'v-checkbox', label:'头痛'},
						{type:'v-checkbox', label:'头晕'},
						{type:'v-checkbox', label:'心慌'},
						{type:'v-checkbox', label:'胸闷'},
						{type:'v-checkbox', label:'反酸'},
						{type:'v-checkbox', label:'恶心'},
						{type:'v-checkbox', label:'腹痛'},
						{type:'v-checkbox', label:'腹胀'},
						{type:'v-checkbox', label:'便血', condition: (patient) => ['肠镜','胃肠镜'].includes(patient.opts.GIE)},
//						{type:'v-checkbox', label:'痔疮', condition: (patient) => ['肠镜','胃肠镜'].includes(patient.opts.GIE)},
						{type:'v-checkbox', label:'肛周疼痛', condition: (patient) => ['肠镜','胃肠镜'].includes(patient.opts.GIE)},
						{type:'v-checkbox', label:'哮喘'},
						{type:'v-checkbox', label:'咳嗽'},
						{type:'v-checkbox', label:'鼻塞'},
						{type:'v-checkbox', label:'打鼾'},
//						{type:'v-checkbox', label:'睡眠呼吸暂停'},
						{type:'v-checkbox', label:'盗汗、发热'},
						{type:'v-textarea', label:'其他', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
				}, {
					caption: '既往病史',
					items: [
						{type:'v-checkbox', label:'无'},
						{type:'v-checkbox', label:'糖尿病'},
						{type:'v-checkbox', label:'高血压'},
						{type:'v-checkbox', label:'脑血管意外'},
						{type:'v-checkbox', label:'冠心病'},
						{type:'v-checkbox', label:'哮喘'},
						{type:'v-checkbox', label:'睡眠呼吸暂停'},
						{type:'v-checkbox', label:'青光眼'},
						{type:'v-checkbox', label:'精神类疾病'},
						{type:'v-checkbox', label:'肺结核'},
						{type:'v-checkbox', label:'甲状腺疾病'},
						{type:'v-checkbox', label:'颌面关节病(损伤)'},
						{type:'v-checkbox', label:'痔疮', condition: (patient) => ['肠镜','胃肠镜'].includes(patient.opts.GIE)},
						{type:'v-textarea', label:'其他', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
				}, {
					caption: '服药史',
					items: [
						{type:'v-checkbox', label:'无'},
						{type:'v-checkbox', label:'抗凝药'},
						{type:'v-checkbox', label:'降压药'},
						{type:'v-checkbox', label:'降糖药'},
						{type:'v-checkbox', label:'治疗结核药物'},
						{type:'v-text-field', label:'其他'},
						{type:'v-text-field', label:'药物名称'},
					]
				}, {
					caption: '消化道肿瘤家族史',
					items: [
						{type:'qradio', label:'家族史', items:['无','有']},
						{type:'v-textarea', label:'说明', attrs:{style:{width:'100%'}, 'single-line':true, rows:1, 'auto-grow':true}},
					]
				}, {
					caption: '张口度',
					items: [
						{type:'qradio', label:'张口度', items:['Ⅰ','Ⅱ','Ⅲ','Ⅳ']},
					]
				}, 
            ]
		}
	},
    methods: {
        getValues() {
			const r = {};
			for(let k in this.$refs) {
				const v = this.$refs[k][0].lazyValue;
				if (v) {
					r[k] = v;
				}
			}
			return r;
        },
		setValues() {
			for(let k in this.$refs) {
				this.$refs[k][0].lazyValue = null;
			}
			if (!this.mypatient) return;
			const qs = this.mypatient.evalQ;
			if (qs) {
				for(let k in qs) {
					if (this.$refs[k] && this.$refs[k][0]) {
						this.$refs[k][0].lazyValue = qs[k]
					}
				}
			}
		},
		print() {
			const b = document.getElementById('eqprint');
			b.click();
		},
		printMR() {
			this.mrs = [];
			this.mrs.push({caption:'主诉', text:this.getMR('检查目的')});
			this.mrs.push({caption:'现病史', text:this.getMR('不适症状')});
			this.mrs.push({caption:'既往史', text:this.getMR('既往病史')});
			this.mrs.push({caption:'服药史', text:this.getMR('服药史')});
			this.mrs.push({caption:'手术史', text:this.getMR('手术史')});
			this.mrs.push({caption:'过敏史', text:this.getMR('过敏史')});
			const el = this.patient.examlist.filter(x => x.value).map(x => x.label).join();
			this.mrs.push({caption:'辅助检查', text:el});
			const tr = this.getMR('检查目的', false) + ',' + this.patient.opts.GIE;
			this.mrs.push({caption:'诊断及处置', text:tr});
			const b = document.getElementById('eqprintmr');
			b.click();
		},
		getMR(caption, f=true) {
			let r = [];
			const a = this.qs.find(x => x.caption === caption);
			for (let e of a.items) {
				const t = `${caption}：${e.label}`;
				const v = this.$refs[t][0].lazyValue;
				if (e.type === 'v-checkbox') {
					if (v) r.push(e.label2 || e.label);
				} else if (f && (e.type === 'v-textarea' || e.type === 'v-text-field')) {
					if (v) r.push(v);
				} else if (e.type === 'qradio') {
					if (v === '无') r.push(v);
				}
			}
			return r.join();
		},
    }
}
</script>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
	margin-right:0!important;
}
table .v-input--selection-controls {
	margin-top:2px!important;
}
.onlyp {
	display: none;
}
.scalep {
	width:900px;
	transform: scale(1.0);
	transform-origin: 0 0;
}
@media print {
   .onlyp {
		display: block;
   }
}
.prttable {
	width:100%;
	border:none!important;
}
.prttable td {
	border:none!important;
	font-size:14px!important;
	line-height:24px!important;
}
.displaynone {
	display: none;
}
</style>