<template>
    <span style="display:flex">
		<v-text-field v-for="item in items" :key="item" dense hide-details reverse :prefix="item" v-model="lazyValuei[item]"/>
    </span>
</template>

<script>
	export default {
		name: 'qnumtxt',
		props: {
			items: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				lazyValuei: {},
			}
		},
		computed: {
			lazyValue: {
				set(v) {
					if (v) {
						this.lazyValuei = v;
					} else {
						this.lazyValuei = {};
					}
				},
				get() {
					return this.lazyValuei;
				}
			}
		},
	}
</script>
