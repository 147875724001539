<template>
    <v-radio-group dense hide-details row v-model="lazyValue">
        <v-radio v-for="item in items" :key="item" dense hide-details :label="item" :value="item"/>
    </v-radio-group>
</template>

<script>
	export default {
		name: 'qradio',
		props: {
			items: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				lazyValue: undefined
			}
		},
	}
</script>
